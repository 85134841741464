<template>
    <v-container fluid>

        Moviendo <span v-text="totalTodos"/> registros de la tabla <span v-text="dominioSubiendo"/><br/>
        Total de registros actualizador <span v-text="contador"/><br/>

        
        <v-row>
            <v-col md=6 cols=12 div >
                    <v-toolbar dark dense color="orange">
                        <v-toolbar-title>
                            Expediente A ELIMINAR
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-fade-transition>
                            <v-btn
                                v-if="datosTodos.length > 0 && pac2.id && pac.id != pac2.id &&!loadingpac2"
                                text
                                dark
                                @click="junta"
                            >
                                <v-icon left>
                                    mdi-account-switch
                                </v-icon>
                                Unir
                            </v-btn>
                        </v-fade-transition>

                        

                        <v-progress-linear
                            height="5"
                            :active="loadingpac"
                            :indeterminate="loadingpac"
                            absolute
                            bottom
                            color="black"
                        />
                    </v-toolbar>

                    <v-scroll-y-transition>
                        <v-alert type="error" v-if="pacError">
                            Este paciente no existe.
                        </v-alert>
                    </v-scroll-y-transition>

                    <datos-paciente
                        :paciente.sync="cip"
                        :datos="pac"
                        :loadingPac="loadingpac"
                        :detalles="datosTodos"
                    />

            </v-col>
            <v-col md=6 cols=12>
                <v-toolbar dark dense color="green">
                    <v-toolbar-title>
                        Expediente A CONSERVAR
                    </v-toolbar-title>
                    <v-progress-linear
                        height="5"
                        :active="loadingpac2"
                        :indeterminate="loadingpac2"
                        absolute
                        bottom
                        color="black"
                    />
                </v-toolbar>

                <v-scroll-y-transition>
                    <v-alert type="error" v-if="pac2Error">
                        Este paciente no existe.
                    </v-alert>
                </v-scroll-y-transition>

                <datos-paciente
                    :paciente.sync="cip2"
                    :datos="pac2"
                    :loadingPac="loadingpac2"
                    :detalles="datosTodos2"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components:{
        'datos-paciente': ()=>import('@/components/admin/joinCip/DatosPaciente')
    },
    data:()=>({
        
        cip:null,
        pac:{},
        datosTodos:[],
        loadingpac:false,
        pacError:false,


        cip2:null,
        pac2:{},
        datosTodos2:[],
        loadingpac2:false,
        pac2Error:false,

        dominioSubiendo:'',
        totalTodos:0,
        contador:0,

    }),
    methods:{
        async cargaPaciente(paciente, destino, destino2){
            try{
                this['loading'+destino]=true
                let req = await this.$http({
                    url:'/pacientes/list',
                    method:'GET',
                    params:{id:paciente}
                })
                this['loading'+destino]=false
                
                if (req.data.total == 1){
                    this[destino] = req.data.root[0]
                    this.cargaDatos(req.data.root[0].id, destino, destino2)
                } else {
                    this[destino]={}
                    this[destino2] = []
                    this[destino+'Error']=true
                    //this.$swal.fire({
                    //    icon: 'warning',
                    //    title: 'No existe',
                    //    text: 'No se encuentran registros para el paciente '+paciente,
                    //})
                }
            }catch(err){
                this['loading'+destino]=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaDatos(pac, destino, destino2){
            this[destino2] = []
            try{
                this['loading'+destino]=true
                let req = await this.$http({
                    url:'/principal/relaciones',
                    method:'GET',
                    params:{id:pac}
                })
                this['loading'+destino]=false
                let datos = req.data

                this[destino2] = datos

            }catch(err){
                this['loading'+destino]=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async junta(){
            this.loadingpac=true
            this.loadingpac2=true

            let params = []
            this.datosTodos.forEach(itm => {
                itm.entradas.forEach(ent => {
                    params.push({
                        columna: itm.columna,
                        tabla: itm.text,
                        entrada: ent.folio,
                        pacDestino: this.pac2.id,
                    })
                })
            })
            try{
                let req = await this.$http({
                    url:'/principal/unePacientes',
                    method:'POST',
                    data:params,
                })
                this.loadingpac=false
                this.loadingpac2=false
                if (req.data.estado){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Registros movidos',
                        text: 'Se han reasignado '+req.data.total+' registros exitosamente',
                    })
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se han podido mover',
                        text: 'Ocurrió un error al reasignar los registros',
                    })
                }
            } catch(err){
                this.loadingpac=false
                this.loadingpac2=false
                this.$store.dispatch('security/revisaError',err)
            }
            //console.log(params)
        },
        /*
        junta(){
            this.contador = 0
            try{
                this.datosTodos.forEach(async itm => {
                    
                    await itm.entradas.forEach( async ent => {
                            
                            let req = await this.$http({
                                url:'/principal/unePacientes',
                                method:'POST',
                                data:{
                                    columna: itm.columna,
                                    tabla: itm.text,
                                    entrada: ent.folio,
                                    pacDestino: this.pac2.id,
                                }
                            })
                            this.contador++
                            this.dominioSubiendo = itm.text
                            this.totalTodos = itm.entradas.length
                            
                            console.log(req.data.estado)
                        
                        
                    })
                    
                    
                })

                this.cargaPaciente(this.cip, 'pac', 'datosTodos')
                this.cargaPaciente(this.cip2, 'pac2', "datosTodos2")
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        */

    },
    watch:{
        cip(val){
            this.pacError = false
            if (val >= 10000099){
                this.cargaPaciente(val, 'pac', 'datosTodos')
            } else{
                this.pac = {}
            }
            
        },
        cip2(val){
            this.pac2Error = false
            if (val >= 10000099){
                this.cargaPaciente(val, 'pac2', "datosTodos2")
            } else{
                this.pac2 = {}
            }
            
        },

    },
}
</script>

<style>

</style>